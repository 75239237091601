
import {cancelSubscription} from "~/api/billing.service";

export default {
  name: 'SubscriptionCard',
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    subscription: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    cancellationLoading: false,
    cancellationModal: false,
    cancellationReason: null
  }),
  computed: {
    textClass() {
      return this.dark ? 'white--text' : 'primary--text'
    },
    product() {
      return this.subscription.items[0].product
    },
    billingCycle() {
      const cycle = this.subscription.items[0].billing_cycle
      if (cycle) {
        if (cycle === 'year')
          return ` (${this.$t('labels.yearly')})`
        else
          return ` (${this.$t('labels.monthly')})`
      }
      return ''
    }
  },
  created() {

  },
  methods: {
    abortCancellation() {
      this.$posthog.capture('cancellation_aborted')
      this.cancellationModal = false
      this.cancellationReason = null
    },
    openCancelModal() {
      this.$posthog.capture('cancellation_started')
      this.cancellationModal = true
    },
    formatDate(date) {
      if (!(date instanceof Date)) {
        date = new Date(date)
      }
      return new Intl.DateTimeFormat('de', {
        dateStyle: 'medium',
        timeStyle: 'short',
      }).format(date)
    },
    async cancelSubscription() {
      this.cancellationLoading = true
      try {
        const updatedSubscription = await this.$api.run(cancelSubscription, this.subscription.id).request
        this.$posthog.capture('cancellation_completed', {reason: this.cancellationReason})
        this.cancellationModal = false
        this.$emit('cancelled', updatedSubscription)
      } catch (e) {
        this.$store.dispatch('setError', this.$t('domains.profile.error_cancelling_subscription'))
        console.error(e)
      } finally {
        this.cancellationLoading = false
      }
    }
  },
}
